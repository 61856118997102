import React, { Component } from 'react';
import { Button, Icons } from '@pik/pik-ui';
import TextField from './components/TextField';
import sendEmailApi from './sendEmailApi';

import { S } from './styles';

const field = [
  {
    name: 'name',
    placeholder: 'Имя',
    type: 'text',
    width: '100%',
  },
  {
    name: 'company',
    placeholder: 'Название компании',
    type: 'text',
    width: '100%',
  },
  {
    name: 'phone',
    placeholder: 'Телефон',
    type: 'newTel',
    width: '48%',
  },
  {
    name: 'email',
    placeholder: 'Email',
    type: 'email',
    width: '48%',
  },
];

class Form extends Component {
  state = {
    phone: null,
    name: null,
    company: null,
    email: null,
    disableButton: true,
    success: false,
    validate: {
      phone: false,
      name: false,
      company: false,
      email: false,
    },
  }

  render() {
    return (
      <S.Wrapper>
        <S.FormContainer>
          { this.renderForm() }
        </S.FormContainer>
        <S.Disclaimer>
          <S.DisclaimerBlock>
            <S.PhoneText>Отдел продаж</S.PhoneText>
            <S.Phone href="tel:+79032135585">+7 903 213 55 85</S.Phone>
            <S.Email href="mailto:sales@pik-element.ru">
              sales@pik-element.ru
              <S.EmailBorder />
            </S.Email>
          </S.DisclaimerBlock>
          <S.DisclaimerBlock>
            <S.PhoneText>Отдел закупок</S.PhoneText>
            <S.Phone href="tel:+74993001498">+7 499 300-14-98 доб.3103</S.Phone>
          </S.DisclaimerBlock>
          <S.DisclaimerBlock>
            <S.PhoneText>Отдел персонала</S.PhoneText>
            <S.Phone href="tel:+74993001443">+7 499 300-14-43</S.Phone>
          </S.DisclaimerBlock>
        </S.Disclaimer>
      </S.Wrapper>
    );
  }

  renderForm = () => (
    <S.Form>
      { !!field && field.map(({
        name, width, placeholder, type,
      }) => (
        <S.Input width={width}>
          <TextField
            name={name}
            placeholder={placeholder}
            value={this.state[name]}
            type={type}
            inputStyle="accent"
            disabled={this.state.success}
            onChange={this.handleChange}
            onValidate={this.handleValidate}
          />
        </S.Input>
      )) }
      <S.Button success={this.state.success} disabled={this.state.disableButton}>
        <Button
          disabled={this.state.disableButton}
          text={this.state.success ? 'Заявка отправлена.' : 'Отправить заявку'}
          type="rounded"
          icon={this.state.success ? 'check' : null}
          onClick={() => this.handleSubmitForm()}
          fullWidth
        />
      </S.Button>
    </S.Form>
  );

  renderIcon = () => <svg width="23" height="22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.18 21.074c.703 0 1.254-.281 1.64-.855L21.79 3.297c.28-.422.398-.809.398-1.172 0-.96-.704-1.652-1.688-1.652-.68 0-1.09.234-1.5.89l-9.867 15.61-5.04-6.352c-.398-.48-.808-.703-1.394-.703-.996 0-1.71.703-1.71 1.676 0 .422.14.82.503 1.242l6.059 7.441c.457.551.96.797 1.629.797z" fill="#000" /></svg>

  handleChange = (name, value) => {
    if (name === 'name' || name === 'company') {
      this.handleValidate(name, !!value);
    }
    this.setState({ [name]: value });
  };

  handleValidate = (name, value) => {
    const { validate } = this.state;

    this.setState({ validate: { ...validate, [name]: !!value } }, this.validateButton);
  };

  validateButton = () => {
    const { validate } = this.state;
    let disableButton = false;

    Object.keys(validate).map((item) => {
      if (!validate[item]) {
        disableButton = true;
      }
    });

    this.setState({ disableButton });
  };

  handleSubmitForm = () => {
    const {
      name, company, phone, email,
    } = this.state;
    if (!this.state.success && !this.state.disableButton) {
      this.setState({ success: true },
        () => setTimeout(() => this.setState({ success: false }), 3000));
      sendEmailApi({
        name, company, email, phone,
      });
    }
  };
}

export default Form;
