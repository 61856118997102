import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputElement from 'react-input-mask';
import { Icons, DropdownBlock } from '@pik/pik-ui';
import validate from './validate';

import {
  TextFieldWrapper,
  Placeholder,
  ErrorMessage,
  SuccessIcon,
  Input,
  Suggestions,
  ShowPasswordIcon,
  Icon,
  Clear,
} from './styles';

class TextField extends Component {
  static propTypes = {
    /** Value input */
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
    /** Name input */
    name: PropTypes.string,
    /** Size input */
    size: PropTypes.oneOf(['m', 'l']),
    /** Type input */
    type: PropTypes.oneOf(['text', 'tel', 'newTel', 'password', 'number', 'email', 'passport', 'date']),
    /** Input styles: basic, accent */
    inputStyle: PropTypes.string,
    /** ID parent forms */
    formId: PropTypes.string,
    /** Placeholder text */
    placeholder: PropTypes.string,
    /** password view button */
    showPassword: PropTypes.bool,
    /** Error message */
    error: PropTypes.string,
    /** Disabled field */
    disabled: PropTypes.bool,
    /** Success field */
    success: PropTypes.bool,
    /** Required field */
    required: PropTypes.bool,
    /** Pattern */
    pattern: PropTypes.string,
    /** className */
    className: PropTypes.string,
    /** Callback function, return: e.target.name, e.target.value, { ...props }, suggestionItem */
    onChange: PropTypes.func,
    /** Callback function, return: e.target.name, validateStatus */
    onValidate: PropTypes.func,
    /** Array suggestions [ { value: 'key' } ] */
    suggestions: PropTypes.arrayOf(PropTypes.object),
    /** Postfix */
    postfix: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    /** Icon name */
    icon: PropTypes.string,
    groupPosition: PropTypes.oneOf(['start', 'middle', 'end', null]),
    /** autoComplete */
    autoComplete: PropTypes.string,
    /** showClear */
    showClear: PropTypes.bool,
    /** autoFocus */
    autoFocus: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    name: 'field',
    placeholder: '',
    error: null,
    pattern: '',
    type: 'text',
    size: 'l',
    showPassword: false,
    disabled: false,
    success: false,
    required: false,
    formId: '',
    className: '',
    onChange: null,
    onValidate: null,
    suggestions: null,
    inputStyle: 'basic',
    postfix: '',
    onBlur: null,
    onFocus: null,
    onKeyDown: null,
    icon: null,
    groupPosition: null,
    autoComplete: null,
    showClear: false,
    autoFocus: false,
  };

  state = {
    autofill: false,
    focus: false,
    error: false,
    message: null,
    showClear: false,
    suggestionsActive: false,
    showPasswordType: null,
  };

  componentDidMount() {
    const { error } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    if (!!error && !!error.length && error !== this.state.message) {
      this.setState({ error: true, message: error });
    }
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    const { message } = this.state;
    // eslint-disable-next-line react/destructuring-assignment
    if (!!error && error !== prevProps.error && !!error.length && error !== message) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ error: true, message: error });
    }

    if (!error && !!prevProps.error && prevProps.error === message) {
      this.setState({ error: false, message: null });
    }
  }

  onClose = () => {
    this.setState({ suggestionsActive: false });
  };

  onChangeSuggestion = (value, item) => {
    const { onChange, name } = this.props;
    if (!!value && onChange) {
      onChange(name, value, this.props, item);
    }

    this.onClose();
  };

  onChange(e) {
    const { suggestionsActive } = this.state;
    const {
      onChange, suggestions, type, onValidate,
    } = this.props;

    const value = type === 'number' || type === 'date' ? e.target.value.replace(/\D/g, '') : e.target.value;

    this.setState({ showClear: value.length > 0 });

    if (!!suggestions && !!suggestions.length && !suggestionsActive) {
      this.setState({ suggestionsActive: true });
    }

    if (onChange) {
      onChange(e.target.name, value, this.props, e);
    }

    if (onValidate && validate.validate[type].test) {
      // eslint-disable-next-line max-len
      onValidate(e.target.name, validate.validate[type].test.test(e.target.value));
    }

    this.setState({ error: false, message: null });
  }

  onBlur(e) {
    const { type, onValidate, onBlur } = this.props;
    this.setState({ focus: false, showClear: false });

    if (validate.validate[type].test) {
      // eslint-disable-next-line max-len
      const validateValue = e.target.value ? validate.validate[type].test.test(e.target.value) : true;
      const textError = !validateValue ? validate.validate[type].message : null;
      this.setState({ error: !validateValue, message: textError });
      if (onValidate && textError) onValidate(e.target.name, validateValue);
    }

    if (onBlur) {
      const value = type === 'number' || type === 'date' ? e.target.value.replace(/\D/g, '') : e.target.value;
      onBlur(e.target.name, value, this.props);
    }
  }

  onFocus(e) {
    const { onFocus, type } = this.props;

    const value = type === 'number' || type === 'date' ? e.target.value.replace(/\D/g, '') : e.target.value;

    this.setState({ focus: true, showClear: value.length > 0 });

    if (onFocus) {
      onFocus(e.target.name, value, this.props);
    }
  }

  onKeyDown(e) {
    const { onKeyDown } = this.props;

    if (onKeyDown) {
      onKeyDown(e);
    }
  }

  onClear = (e) => {
    const { name, onChange } = this.props;

    if (onChange) {
      onChange(name, '', this.props, e);
    }
  };

  setAutofill = (value) => {
    this.setState({ autofill: value });
  };

  handleOnInputAutofill = (e) => {
    const { value } = this.props;
    const hasDataProperty = 'data' in e;
    const hasAutofill = !hasDataProperty && !!(value && !value.length);
    this.setAutofill(hasAutofill);
  };

  handleAutofillAnimation = (e) => {
    if (e.animationName === 'onAutoFillStart') {
      this.setAutofill(true);
    } else if (e.animationName === 'onAutoFillCancel') {
      this.setAutofill(false);
    }
  };

  renderInput(focus, error, success) {
    const {
      value, name, type, disabled, pattern, required, formId, size, inputStyle, postfix, icon,
      groupPosition, autoComplete, placeholder, autoFocus,
    } = this.props;

    const { showPasswordType } = this.state;

    let currentValue = value;
    if (value && !focus) {
      currentValue = value + postfix;
    }

    const props = {
      pattern,
      required,
      disabled,
      name,
      value: currentValue || '',
      form: formId,
      type: showPasswordType ? 'text' : validate.validate[type].type,
      mask: validate.validate[type].mask || '',
      maskChar: '',
      autoComplete,
      autoFocus,
      onInput: (e) => this.handleOnInputAutofill(e),
      onChange: (e) => this.onChange(e),
      onBlur: (e) => this.onBlur(e),
      onFocus: (e) => this.onFocus(e),
      onKeyDown: (e) => this.onKeyDown(e),
    };

    return (
      <Input
        size={size}
        focus={focus}
        value={value}
        disabled={disabled}
        error={error}
        success={success}
        inputStyle={inputStyle}
        icon={icon}
        groupPosition={groupPosition}
        placeholder={placeholder}
        onAnimationStart={this.handleAutofillAnimation}
      >
        <InputElement {...props} />
      </Input>
    );
  }

  static renderError(message) {
    return <ErrorMessage dangerouslySetInnerHTML={{ __html: message }} />;
  }

  renderPlaceholder(placeholder, required, size, error, success) {
    const {
      value, inputStyle, icon, disabled,
    } = this.props;
    const { focus, autofill } = this.state;
    const placeholderValue = required ? `${placeholder} *` : placeholder;
    return (
      <Placeholder
        value={value}
        focus={focus}
        size={size}
        disabled={disabled}
        error={error}
        success={success}
        inputStyle={inputStyle}
        icon={icon}
        autofill={autofill}
        dangerouslySetInnerHTML={{ __html: placeholderValue }}
      />
    );
  }

  renderShowPasswordIcon() {
    const { showPasswordType } = this.state;
    return (
      <ShowPasswordIcon
        active={showPasswordType}
        onClick={() => this.setState({ showPasswordType: !showPasswordType })}
      >
        <Icons type="glass" size="m" />
      </ShowPasswordIcon>
    );
  }

  renderSuggestions = (item, i) => (
    <Suggestions key={i} onClick={() => this.onChangeSuggestion(item.text, item)}>
      { item.text }
    </Suggestions>
  );

  renderIcon = () => {
    const { focus, error } = this.state;
    const {
      icon, success, inputStyle, value,
    } = this.props;
    return (
      <Icon focus={focus} error={error} success={success} inputStyle={inputStyle} value={value}>
        <Icons type={icon} size="s" />
      </Icon>
    );
  };

  renderClear = () => {
    const { value, size } = this.props;
    const { showClear } = this.state;
    return (
      <Clear onClick={this.onClear} value={value} show={showClear} size={size}>
        <Icons type="close" size="xs" />
      </Clear>
    );
  };

  render() {
    const {
      focus, error, message, suggestionsActive,
    } = this.state;
    const {
      placeholder, className, required, size, success, suggestions, disabled, showPassword, icon,
      showClear,
    } = this.props;

    return (
      <TextFieldWrapper
        className={className}
        size={size}
        ref={(e) => this.contentRef = e}
        disabled={disabled}
      >
        { placeholder && this.renderPlaceholder(placeholder, required, size, error, success) }
        { this.renderInput(focus, error, success) }
        <DropdownBlock
          outsideClickRef={this.contentRef}
          onClose={this.onClose}
          active={suggestionsActive}
        >
          { !!suggestions && !!suggestions.length
          && suggestions.map((item, i) => this.renderSuggestions(item, i))}
        </DropdownBlock>
        { error && message && TextField.renderError(message) }
        { !!showPassword && this.renderShowPasswordIcon() }
        { !!success && <SuccessIcon><Icons type="check" size="m" /></SuccessIcon> }
        { !!icon && this.renderIcon() }
        { showClear && this.renderClear() }
      </TextFieldWrapper>
    );
  }
}

export default TextField;
