import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import throttle from 'lodash.throttle';
import { data } from '../data';
import Items from '../../components/Items';

import { S } from './styles';

const calculateHeight = (height, accordionBlockMinHeight) => {
  let count = 0;
  let accordionAddHeight = 0;

  data.forEach((item) => {
    const { content, accordion } = item;
    count += content.length;

    if (accordion && height < accordionBlockMinHeight) {
      count -= content.length;
      accordionAddHeight = accordionBlockMinHeight;
    }
  });

  return count * height + accordionAddHeight;
};

const PikElementContainer = () => {
  const [height, handleHeight] = useState(0);
  const [scroll, handleScroll] = useState(0);
  const accordionBlockMinHeight = height ? 1000 : 0;
  const accordionHeight = height * 0.095 > 80 ? height * 0.095 : 80;
  const containerHeight = calculateHeight(height, accordionBlockMinHeight);

  useEffect(() => {
    const { body: { clientHeight } } = document;
    handleHeight(clientHeight);

    const listenScroll = () => {
      const { pageYOffset } = window;
      handleScroll(pageYOffset);
    };

    const throttleScroll = throttle(listenScroll, 40);

    window.addEventListener('scroll', throttleScroll);

    return () => {
      window.removeEventListener('scroll', throttleScroll);
    };
  }, []);

  return (
    <>
      <Head>
        <title>ПИК-Модуль</title>
      </Head>
      <S.PikElementContainer height={containerHeight}>
        <Items
          items={data}
          scroll={scroll}
          height={height}
          accordionHeight={accordionHeight}
          accordionBlockMinHeight={accordionBlockMinHeight}
        />
      </S.PikElementContainer>
    </>
  );
};

export default PikElementContainer;
