import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@pik/pik-ui';

import { S } from './styles';

const Header = ({ isLeft, isWhite, mobileGradient }) => (
  <S.Header isLeft={isLeft} isWhite={isWhite} mobileGradient={mobileGradient}>
    <Typography weight="semiBold" equslLineHeight>
      ПИК-Модуль
    </Typography>
    <S.Logo isWhite={isWhite}>
      <svg viewBox="0 0 54 54"><path d="M1 34V18h15.533v16H12.05V21.968H5.483V34H1zm28.773-9.259L22.601 34h-3.983V18h4.483v9.259L30.273 18h3.983v16h-4.483v-9.259zm11.05 2.816V34h-4.482V18h4.483v6.635L46.182 18h5.233l-6.38 7.765L53 34h-6.026l-6.15-6.443z"/></svg>
    </S.Logo>
  </S.Header>
);

Header.propTypes = {
  isLeft: PropTypes.bool,
  isWhite: PropTypes.bool,
  mobileGradient: PropTypes.string.isRequired,
};

Header.defaultProps = {
  isLeft: false,
  isWhite: false,
};

export default Header;
