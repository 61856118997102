import styled from 'styled-components';
import { mixins, media } from '@pik/pik-ui';

export const S = {};

S.Video = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: ${({ showFull }) => (showFull ? 0 : 0.4)};
    ${mixins.animation('opacity')};
    pointer-events: none;
  }
  
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

S.Play = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${mixins.flex()};
  background: #fff;
  border-radius: 100%;
  width: 120px;
  height: 120px;
  cursor: pointer;
  opacity: ${({ showFull }) => (showFull ? 0 : 1)};
  visibility: ${({ showFull }) => (showFull ? 'hidden' : 'visible')};
  ${mixins.animation('opacity, visibility')};
  z-index: 2;
  
  svg {
    width: 100%;
    height: 100%;
  }
  
  @media ${media.max768} {
    width: 60px;
    height: 60px;
  }
`;
