import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { S } from './styles';

const Video = ({ video, screenType }) => {
  const {
    desktop, mobile, coverDesk, coverMob, full,
  } = video;
  const isMobile = screenType === 'mobile';
  const [showFull, handleShowFull] = useState(false);
  const poster = isMobile ? coverMob : coverDesk;
  let link = isMobile ? mobile : desktop;

  if (showFull) {
    link = full;
  }

  return (
    <S.Video showFull={showFull}>
      <video
        autoPlay
        muted
        playsInline
        loop
        controls={false}
        poster={poster}
        src={link}
      />
      <S.Play showFull={showFull} onClick={() => handleShowFull(true)}>
        <svg viewBox="0 0 120 120" fill="none">
          <path d="M60 .5c32.856 0 59.5 26.644 59.5 59.5S92.856 119.5 60 119.5.5 92.856.5 60 27.144.5 60 .5z" fill="#fff" stroke="#fff" />
          <path d="M79.65 58.032L52.792 41.356c-.737-.45-1.704-.481-2.457-.047a2.463 2.463 0 00-1.244 2.14v33.2c0 .883.476 1.706 1.229 2.14.368.202.767.31 1.182.31.446 0 .876-.124 1.26-.356L79.62 62.205a2.446 2.446 0 001.167-2.078 2.36 2.36 0 00-1.136-2.095z" fill="#000" />
        </svg>
      </S.Play>
    </S.Video>
  );
};

Video.propTypes = {
  video: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
    coverDesk: PropTypes.string,
    coverMob: PropTypes.string,
    full: PropTypes.string,
  }).isRequired,
  screenType: PropTypes.string,
};

Video.defaultProps = {
  screenType: 'desktop',
};

const mapStateToProps = ({ appService }) => ({
  screenType: appService.screenType,
});

export default connect(mapStateToProps)(Video);
