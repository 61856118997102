import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { Typography } from '@pik/pik-ui';
import Header from '../Header';
import Video from '../Video';
import Form from '../Form';
import * as lottieData from '../../containers/lottie.json';

import { S } from './styles';

const createMobileTitle = (content) => {
  const result = {};

  content.forEach(({ title, desc }) => {
    result.title = title;
    result.desc = desc;
  });

  return result;
};

const getHeadText = (title, desc) => {
  let result = `<span>${ title }</span>`;

  if (desc) {
    result = `<span>${ title }</span><br/>${ desc }`;
  }

  return result;
};

const getContentText = (content) => {
  let result = '';

  content.forEach(({ text }) => {
    if (text) {
      result = `${ result }<p>${ text }</p>`;
    }
  });

  return result;
};

const renderTextHead = (textHeadData, isVideo, isAccordion) => {
  const { title, desc } = textHeadData;

  if (title && !isVideo) {
    const text = getHeadText(title, desc);

    return (
      <S.TextHead isAccordion={isAccordion}>
        <Typography weight="semiBold" dangerouslySetInnerHTML={text} />
      </S.TextHead>
    );
  }

  return null;
};

const renderVideo = (video, height) => {
  if (video) {
    return (
      <S.Video height={height}>
        <Video video={video} />
      </S.Video>
    );
  }
  return null;
};

const handleSvgHover = (e, i, svgRef, mobileLeft, handleSvgTooltip) => {
  const { current } = svgRef;
  const { target } = e;
  const { top: svgTop, left: svgLeft } = current.getBoundingClientRect();
  const { top, left, width } = target.getBoundingClientRect();
  let transform = 'translateX(0)';

  if (mobileLeft) {
    transform = `translateX(calc(-100% + ${ width }px))`;
  }

  handleSvgTooltip({
    id: `svg_text_${ i }`,
    top: top - svgTop,
    left: left - svgLeft,
    transform,
  });
};

const renderSvg = (svg, svgTooltip, handleSvgTooltip, svgRef) => {
  if (svg) {
    return (
      <>
        <S.Svg ref={svgRef}>
          { svg.map(({ code, text, mobileLeft }, i) => (
            <S.SvgGroup
              onTouchStart={(e) => handleSvgHover(e, i, svgRef, mobileLeft, handleSvgTooltip)}
              id={`svg_group_${ i }`}
              key={`svg_group_${ text }`}
            >
              { code.map((d) => (
                <S.Path key={d} d={d} />
              )) }
            </S.SvgGroup>
          )) }
        </S.Svg>
        { svg.map(({ text }, i) => (
          <S.PathText
            svgTooltip={svgTooltip}
            id={`svg_text_${ i }`}
            key={`svg_text_${ text }`}
            onTouchEnd={() => handleSvgTooltip(null)}
          >
            <Typography weight="medium" dangerouslySetInnerHTML={text} />
          </S.PathText>
        )) }
      </>
    );
  }
  return null;
};

const renderBg = (lottie, image, svg, svgTooltip, handleSvgTooltip, svgRef) => {
  if (lottie) {
    const { default: animationData } = lottieData;

    return (
      <S.Lottie>
        <Lottie options={{ animationData, loop: true, autoplay: true }} />
      </S.Lottie>
    );
  }

  if (image) {
    return (
      <S.Image>
        <S.Img src={image} />
        { renderSvg(svg, svgTooltip, handleSvgTooltip, svgRef) }
      </S.Image>
    );
  }

  return null;
};

const renderText = (content) => {
  const text = getContentText(content);

  if (text) {
    return (
      <S.TextBlock>
        <Typography dangerouslySetInnerHTML={text} />
      </S.TextBlock>
    );
  }

  return null;
};

const renderTableRight = (tableRight) => {
  if (tableRight) {
    return (
      <S.Table>
        { tableRight.map((item) => {
          const { name, text } = item;

          return (
            <S.Item>
              <S.Name>
                <Typography weight="semiBold" dangerouslySetInnerHTML={name} />
              </S.Name>
              <S.Text>
                <Typography dangerouslySetInnerHTML={text} />
              </S.Text>
            </S.Item>
          );
        }) }
      </S.Table>
    );
  }

  return null;
};

const renderAccordion = (accordion) => {
  if (accordion) {
    return (
      <S.Accordion>
        { accordion.map(({ text, mobileImage }) => (
          <S.AccordionItem ket={mobileImage}>
            <S.AccordionImage bg={mobileImage} />
            <S.AccordionText>
              <Typography weight="medium" dangerouslySetInnerHTML={text} />
            </S.AccordionText>
          </S.AccordionItem>
        )) }
      </S.Accordion>
    );
  }
  return null;
};

const renderForm = (form) => {
  if (form) {
    return (
      <Form />
    );
  }
  return null;
};

const MobileItem = ({ item, screenHeight }) => {
  const svgRef = useRef(null);
  const [svgTooltip, handleSvgTooltip] = useState(null);
  const {
    bg, header, content, accordion, form,
  } = item;
  const {
    color, lottie, image, svg,
  } = bg;
  const { isLeft, isWhite, mobileGradient } = header;
  const textHeadData = createMobileTitle(content);
  const { video, tableRight } = content[0];
  const isVideo = !!video;
  const isAccordion = !!accordion;

  return (
    <S.MobileItem minHeight={screenHeight} bg={color}>
      { renderVideo(video, screenHeight) }
      <Header isLeft={isLeft} isWhite={isWhite} mobileGradient={mobileGradient} />
      <S.Content>
        { renderTextHead(textHeadData, isVideo, isAccordion) }
        { renderBg(lottie, image, svg, svgTooltip, handleSvgTooltip, svgRef) }
        { renderText(content) }
        { renderTableRight(tableRight) }
        { renderAccordion(accordion) }
        { renderForm(form) }
      </S.Content>
    </S.MobileItem>
  );
};

MobileItem.propTypes = {
  item: PropTypes.shape({
    bg: PropTypes.shape({
      color: PropTypes.string,
      lottie: PropTypes.bool,
      image: PropTypes.string,
      svg: PropTypes.shape({}),
    }),
    header: PropTypes.shape({
      isLeft: PropTypes.bool,
      isWhite: PropTypes.bool,
      mobileGradient: PropTypes.string,
    }),
    content: PropTypes.shape({
      video: PropTypes.shape({}),
    }),
    accordion: PropTypes.shape({}),
    form: PropTypes.bool,
  }).isRequired,
  screenHeight: PropTypes.number.isRequired,
};

MobileItem.defaultProps = {};

export default MobileItem;
