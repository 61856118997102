import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@pik/pik-ui';

import { S } from './styles';

const getHeight = (accordion, accordionHeight) => accordion.length * accordionHeight;

const calculateTop = (i, accordionHeight, scroll, topLimit) => {
  let result = i * accordionHeight;

  if (scroll > topLimit) {
    const check = scroll - topLimit;

    if (result - check > 0) {
      result -= check;
    } else {
      result = 0;
    }
  }

  return result;
};

const renderItem = (item, i, accordionHeight, scroll, topLimit, color) => {
  const { text, image } = item;
  const top = calculateTop(i, accordionHeight, scroll, topLimit);

  return (
    <S.Item height={accordionHeight} top={top} key={text} bg={color}>
      <S.Bg bg={image} height={accordionHeight} />
      <Typography weight="medium" dangerouslySetInnerHTML={text} />
    </S.Item>
  );
};

const Accordion = ({
  accordion, accordionHeight, scroll, topLimit, color,
}) => {
  const height = getHeight(accordion, accordionHeight);

  return (
    <S.Accordion height={height}>
      { accordion.map((item, i) => renderItem(item, i, accordionHeight, scroll, topLimit, color)) }
    </S.Accordion>
  );
};

Accordion.propTypes = {
  accordion: PropTypes.shape([{
    text: PropTypes.string,
    image: PropTypes.string,
  }]).isRequired,
  accordionHeight: PropTypes.number.isRequired,
  scroll: PropTypes.number.isRequired,
  topLimit: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default Accordion;
