import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Header';
import Content from '../Content';
import { data } from '../../containers/data';

import { S } from './styles';

const calculateNaturalHeight = (height, content, accordion, accordionBlockMinHeight) => {
  let result = height * content.length;

  if (accordion && height < accordionBlockMinHeight) {
    result = accordionBlockMinHeight;
  }

  return result;
};

const calculateTopLimit = (index, height, accordionBlockMinHeight) => {
  let result = 0;

  data.forEach((block, i) => {
    const { content, accordion } = block;
    if (index && i < index) {
      result += calculateNaturalHeight(height, content, accordion, accordionBlockMinHeight);
    }
  });

  return result;
};

const calculateTop = (scroll, height, topLimitScroll, topLimit, bottomLimit) => {
  let result = height;

  if (scroll > topLimitScroll && scroll < topLimit) {
    result = height - (scroll - topLimitScroll);
  }

  if (scroll >= topLimit && scroll <= bottomLimit) {
    result = 0;
  }

  return result;
};

const renderContent = (
  content,
  bg,
  height,
  header,
  isOverflow,
  overflowPosition,
  scroll,
  topLimitScroll,
  bottomLimit,
  topLimit,
  accordion,
  accordionHeight,
  accordionBlockMinHeight,
  form,
) => {
  const { isLeft, isWhite } = header;
  const { length } = content;
  const top = overflowPosition * -1;
  const contentHeight = accordion && height < accordionBlockMinHeight
    ? accordionBlockMinHeight
    : height;

  return (
    <S.Content height={contentHeight * length} isOverflow={isOverflow} top={top}>
      <Content
        content={content}
        bg={bg}
        height={contentHeight}
        scroll={scroll}
        topLimitScroll={topLimitScroll}
        bottomLimit={bottomLimit}
        topLimit={topLimit}
        accordion={accordion}
        accordionHeight={accordionHeight}
        form={form}
      />
      <Header isLeft={isLeft} isWhite={isWhite} />
    </S.Content>
  );
};

const renderBlock = (
  scroll, block, i, height, accordionHeight, accordionBlockMinHeight,
) => {
  const {
    bg,
    header,
    isOverflow,
    content,
    accordion,
    form,
  } = block;
  const { color } = bg;
  const naturalHeight = calculateNaturalHeight(height, content, accordion, accordionBlockMinHeight);
  const topLimit = calculateTopLimit(i, height, accordionBlockMinHeight);
  const topLimitScroll = topLimit - naturalHeight;
  const bottomLimit = (topLimit + naturalHeight) * content.length;
  const top = calculateTop(scroll, naturalHeight, topLimitScroll, topLimit, bottomLimit);
  const blockHeight = isOverflow ? naturalHeight - top : naturalHeight;

  // console.log(`--- ${i} ---`);
  // console.log('scroll', scroll);
  // console.log('naturalHeight', naturalHeight);
  // console.log('topLimit', topLimit);
  // console.log('topLimitScroll', topLimitScroll);
  // console.log('bottomLimit', bottomLimit);
  // console.log('blockHeight', blockHeight);

  return (
    <S.Block
      key={`${content.length}_${i}`}
      height={blockHeight}
      bg={color}
      top={top}
      isOverflow={isOverflow}
    >
      { renderContent(
        content,
        bg,
        height,
        header,
        isOverflow,
        top,
        scroll,
        topLimitScroll,
        bottomLimit,
        topLimit,
        accordion,
        accordionHeight,
        accordionBlockMinHeight,
        form,
      ) }
    </S.Block>
  );
};

const Items = ({
  scroll, items, height, accordionHeight, accordionBlockMinHeight,
}) => (
  <S.Items>
    { items.map((block, i) => renderBlock(
      scroll, block, i, height, accordionHeight, accordionBlockMinHeight,
    )) }
  </S.Items>
);

Items.propTypes = {
  scroll: PropTypes.number.isRequired,
  items: PropTypes.shape([]).isRequired,
  height: PropTypes.number.isRequired,
  accordionHeight: PropTypes.number.isRequired,
  accordionBlockMinHeight: PropTypes.number.isRequired,
};

export default Items;
