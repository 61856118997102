import styled from 'styled-components';
import { mixins, styleHelpers } from '@pik/pik-ui';

export const S = {};

S.Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

S.BackGround = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${ ({ height }) => `${ height }px` };
  ${ mixins.flex() };
`;

S.Image = styled.div`
  box-sizing: border-box;
  ${ mixins.flexWidth('600px') };
  height: 800px;
  position: relative;
  background: ${ ({ bg }) => (bg ? `url(${ bg }) no-repeat center` : 'transparent') };
  background-size: contain;
`;

S.Svg = styled.svg.attrs(() => ({
  viewBox: '0 0 600 800',
}))`
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

S.SvgGroup = styled.g`
  cursor: pointer;
  position: relative;
  opacity: ${ ({ showTooltips }) => (showTooltips ? 1 : 0) };
  ${ mixins.animation('opacity') };
`;

S.Path = styled.path`
  fill: #fff;
  
  &:first-child {
    fill: #FF4114;
  }
`;

S.PathText = styled.div`
  box-sizing: border-box;
  position: absolute;
  white-space: nowrap;
  background: #000;
  padding: 10px;
  min-height: 32px;
  border-radius: 8px;
  z-index: 2;
  cursor: pointer;
  ${ mixins.flex() };
  
  ${ ({ svgTooltip, id }) => (svgTooltip && id === svgTooltip.id ? `
    opacity: 1;
    visibility: visible;
    top: ${ svgTooltip.top }px;
    left: ${ svgTooltip.left }px;
  ` : `
    opacity: 0;
    visibility: hidden;
  `) }
  
  .Typography {
    color: #fff;
    font-size: 12px;
    line-height: 14px;
  }
`;

S.Lottie = styled.div`
  box-sizing: border-box;
  ${ mixins.flexWidth('600px') };
  height: 800px;
`;

S.Wrapper = styled.div.attrs(({ top }) => ({
  style: { top: `${ top }px` },
}))`
  height: ${ ({ height }) => `${ height }px` };
  box-sizing: border-box;
  position: absolute;
  left: 0;
  width: 100%;
`;

S.Item = styled.div`
  height: ${ ({ height }) => `${ height }px` };
  width: 100%;
  box-sizing: border-box;
  ${ ({ isCenter }) => (isCenter ? mixins.flex() : null) };
`;

S.Video = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${ ({ height }) => `${ height }px` };
  position: absolute;
  top: 0;
  left: 0;
`;

S.TextCenter = styled.div.attrs(({ opacity }) => ({
  style: { opacity },
}))`
  box-sizing: border-box;
  width: 100%;
  max-width: 880px;
  text-align: center;
  
  .Typography {
    font-size: 32px;
    line-height: 40px;
    color: #fff;
  }
`;

S.TextHead = styled.div.attrs(({ opacity }) => ({
  style: { opacity },
}))`
  box-sizing: border-box;
  width: 100%;
  max-width: 440px;
  position: absolute;
  top: 20px;
  left: 20px;
  
  .Typography {
    font-size: 26px;
    line-height: 30px;
    color: ${ ({ isVideo, isAccordion }) => ((isVideo || isAccordion) ? '#fff' : '#939393') };
    
    span {
      color: ${ ({ isAccordion }) => (isAccordion ? '#000' : '#FF4114') };
    }
  }
`;

S.TableRight = styled.div`
  box-sizing: border-box;
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 280px;
`;

S.TableRightItem = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid ${ styleHelpers.colors.grayExtraLight };
  padding: 16px 0;
  
  &:last-child {
    border-bottom: none;
  }
`;

S.TableRightItemName = styled.div`
  box-sizing: border-box;
  margin-bottom: 8px;
  
  .Typography {
    font-size: 24px;
    line-height: 24px;
    color: #000;
  }
`;

S.TableRightItemText = styled.div`
  box-sizing: border-box;
  
  .Typography {
    font-size: 12px;
    line-height: 12px;
    color: #939393;
  }
`;

S.TableLeft = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 440px;
`;

S.TableLeftItem = styled.div`
  box-sizing: border-box;
  border-top: 1px solid #fff;
  padding: 16px 0;
  
  .Typography {
    font-size: 15px;
    line-height: 15px;
    color: #fff;
  }
`;

S.Accordion = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`;
