export const data = [
  {
    bg: {
      color: '#000',
      lottie: true,
    },
    header: {
      isLeft: true,
      isWhite: true,
      mobileGradient: 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)',
    },
    content: [
      {
        text: 'Сантехнический модуль&nbsp;&mdash; это цельный санузел с&nbsp;полом, стенами, потолком, отделкой и&nbsp;сантехникой, полностью изготовленный на&nbsp;заводе. Готовый модуль в&nbsp;упакованном виде доставляется на&nbsp;стройку и&nbsp;при помощи крана устанавливается на&nbsp;нужное место.',
      },
      {
        text: 'Производим сантехнические модули для жилых монолитных и&nbsp;панельных домов, гостиниц, больниц, домов отдыха. А&nbsp;также для любых других зданий и&nbsp;проектов, где они могут понадобиться.',
      },
    ],
  },
  {
    isOverflow: true,
    bg: {
      color: '#fff',
      image: '//0.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/3_d04d432787281b36c4153f300dbf3960.png',
    },
    header: {
      isLeft: false,
      isWhite: false,
      mobileGradient: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
    },
    content: [
      {
        title: 'Почему СТМ?',
        desc: 'Установка готовых сантехкабин сокращает операции на&nbsp;стройке, повышает качество отделки, сокращает сроки строительства и&nbsp;снижает расходы.',
        tableRight: [
          {
            name: 'Долговечность',
            text: 'Кабина прослужит больше 20 лет',
          },
          {
            name: 'Скорость монтажа',
            text: 'Установка занимает 1 час',
          },
          {
            name: 'Стоимость',
            text: 'Ниже аналогов',
          },
          {
            name: 'Качество',
            text: 'Высокие стандарты сборки и отделки',
          },
        ],
      },
    ],
  },
  {
    isOverflow: true,
    bg: {
      color: '#323232',
      image: '//1.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/4_931834510de3ce969baeedda8bd1a375.png',
      svg: [
        {
          code: [
            'M57.4 287c8.84 0 16 7.16 16 16s-7.16 16-16 16-16-7.16-16-16 7.16-16 16-16z',
            'M62.73 303c0 .59-.48 1.07-1.07 1.07h-3.2v3.2a1.071 1.071 0 01-2.14 0v-3.2h-3.2a1.071 1.071 0 010-2.14h3.2v-3.2a1.071 1.071 0 012.14 0v3.2h3.2c.6 0 1.07.48 1.07 1.07z',
          ],
          text: 'Дренаж кондиционера',
          mobileLeft: false,
        },
        {
          code: [
            'M120.4 293c8.84 0 16 7.16 16 16s-7.16 16-16 16-16-7.16-16-16 7.16-16 16-16z',
            'M125.73 309c0 .59-.48 1.07-1.07 1.07h-3.2v3.2a1.071 1.071 0 01-2.14 0v-3.2h-3.2a1.071 1.071 0 010-2.14h3.2v-3.2a1.071 1.071 0 012.14 0v3.2h3.2c.6 0 1.07.48 1.07 1.07z',
          ],
          text: 'Электропроводка',
          mobileLeft: false,
        },
        {
          code: [
            'M64.4 496c8.84 0 16 7.16 16 16s-7.16 16-16 16-16-7.16-16-16 7.16-16 16-16z',
            'M69.73 512c0 .59-.48 1.07-1.07 1.07h-3.2v3.2a1.071 1.071 0 01-2.14 0v-3.2h-3.2a1.071 1.071 0 010-2.14h3.2v-3.2a1.071 1.071 0 012.14 0v3.2h3.2c.6 0 1.07.48 1.07 1.07z',
          ],
          text: 'Влагостойкий<br/>гипсокартонный<br/>лист',
          mobileLeft: false,
        },
        {
          code: [
            'M383.4 359c8.84 0 16 7.16 16 16s-7.16 16-16 16-16-7.16-16-16 7.16-16 16-16z',
            'M388.73 375c0 .59-.48 1.07-1.07 1.07h-3.2v3.2a1.071 1.071 0 01-2.14 0v-3.2h-3.2a1.071 1.071 0 010-2.14h3.2v-3.2a1.071 1.071 0 012.14 0v3.2h3.2c.6 0 1.07.48 1.07 1.07z',
          ],
          text: 'Профиль<br/>собственного<br/>производства',
          mobileLeft: false,
        },
        {
          code: [
            'M530.4 421c8.84 0 16 7.16 16 16s-7.16 16-16 16-16-7.16-16-16 7.16-16 16-16z',
            'M535.73 437c0 .59-.48 1.07-1.07 1.07h-3.2v3.2a1.071 1.071 0 01-2.14 0v-3.2h-3.2a1.071 1.071 0 010-2.14h3.2v-3.2a1.071 1.071 0 012.14 0v3.2h3.2c.6 0 1.07.48 1.07 1.07z',
          ],
          text: 'Разводка труб ХГВС',
          mobileLeft: true,
        },
        {
          code: [
            'M398.4 540c8.84 0 16 7.16 16 16s-7.16 16-16 16-16-7.16-16-16 7.16-16 16-16z',
            'M403.73 556c0 .59-.48 1.07-1.07 1.07h-3.2v3.2a1.071 1.071 0 01-2.14 0v-3.2h-3.2a1.071 1.071 0 010-2.14h3.2v-3.2a1.071 1.071 0 012.14 0v3.2h3.2c.6 0 1.07.48 1.07 1.07z',
          ],
          text: 'Скрытый бачок',
          mobileLeft: false,
        },
        {
          code: [
            'M470.4 497c8.84 0 16 7.16 16 16s-7.16 16-16 16-16-7.16-16-16 7.16-16 16-16z',
            'M475.73 513c0 .59-.48 1.07-1.07 1.07h-3.2v3.2a1.071 1.071 0 01-2.14 0v-3.2h-3.2a1.071 1.071 0 010-2.14h3.2v-3.2a1.071 1.071 0 012.14 0v3.2h3.2c.6 0 1.07.48 1.07 1.07z',
          ],
          text: 'Разводка канализации',
          mobileLeft: true,
        },
        {
          code: [
            'M504.4 239c8.84 0 16 7.16 16 16s-7.16 16-16 16-16-7.16-16-16 7.16-16 16-16z',
            'M509.73 255c0 .59-.48 1.07-1.07 1.07h-3.2v3.2a1.071 1.071 0 01-2.14 0v-3.2h-3.2a1.071 1.071 0 010-2.14h3.2v-3.2a1.071 1.071 0 012.14 0v3.2h3.2c.6 0 1.07.48 1.07 1.07z',
          ],
          text: 'Стояк канализации',
          mobileLeft: true,
        },
      ],
    },
    header: {
      isLeft: false,
      isWhite: true,
      mobileGradient: 'linear-gradient(180deg, #323232 0%, rgba(50, 50, 50, 0) 100%)',
    },
    content: [
      {
        title: 'Как это устроено?',
        desc: 'Стены, пол и&nbsp;потолок создаются <nobr>по-отдельности</nobr>, после чего соединяются в&nbsp;единый модуль. Затем монтируются инженерные системы и&nbsp;сантехника. В&nbsp;конце проводятся все необходимые испытания.',
      },
    ],
  },
  {
    isOverflow: true,
    bg: {
      color: '#fff',
    },
    header: {
      isLeft: false,
      isWhite: true,
      mobileGradient: 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)',
    },
    content: [
      {
        title: 'Производство.',
        desc: 'Эффективное современное производство с&nbsp;автоматическим конвейером. Высокая производительность и&nbsp;низкая себестоимость.',
        video: {
          desktop: '//0.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/fragment-desktop_54279e495106f51cfbdd9b2bfd97f091.mp4',
          mobile: '//0.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/fragment-mobile_5bff9631fcc53d091c1469beadc03563.mp4',
          coverDesk: '//2.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/cover-desktop_07c340877c5965a42e6ed89948ebb5a0.jpg',
          coverMob: '//1.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/cover-mobile_6b4e80377a62c390a86709016643b8ad.jpg',
          full: '//0.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/full-video_3815b257da5948c7521794e66bfda266.mp4',
        },
        tableLeft: [
          '100% визуальный и&nbsp;инструментальный контроль качества',
          'Собственная технология укладки плитки',
          'Мощность 50&nbsp;000 кабин в&nbsp;год',
          'Больше 250&nbsp;модификаций',
        ],
      },
    ],
  },
  {
    isOverflow: true,
    bg: {
      color: '#FF4114',
    },
    header: {
      isLeft: false,
      isWhite: true,
      mobileGradient: 'linear-gradient(180deg, #FF4114 0%, rgba(255, 65, 20, 0) 100%)',
    },
    content: [
      {
        title: 'Этапы работы.',
        desc: 'Поддержка партнёров 360&deg;. Помогаем во&nbsp;всём, начиная с&nbsp;технического задания.',
      },
    ],
    accordion: [
      {
        text: 'Составление технического задания',
        image: '//0.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/1_a015abebc730711aca66eaea62fb9241.svg',
        mobileImage: '//0.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/1_06581da164e9670f4f9730bd601529e9.png',
      },
      {
        text: 'Расчёт стоимости и заключение контракта',
        image: '//2.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/2_fec08605a779534a3f45f6cd9e7f7bba.svg',
        mobileImage: '//2.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/2_2699ff0123498e75accf39fdd28baf09.png',
      },
      {
        text: 'Проектирование',
        image: '//2.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/3_4a583772c3f441ffb0d9a4f23d82188a.svg',
        mobileImage: '//2.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/3_146439cee251d3d79965972db46a6ff8.png',
      },
      {
        text: 'Изготовление образца (при необходимости)',
        image: '//0.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/4_7443ad00ddec022fa3c46f812a3c4260.svg',
        mobileImage: '//1.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/4_c886931f5f1031fcc9e6c87dcae9354d.png',
      },
      {
        text: 'Серийное производство',
        image: '//1.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/5_d3ed485fed032620cdcf8520639ab58e.svg',
        mobileImage: '//0.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/5_30528de0b57d047f068900c65888b30a.png',
      },
      {
        text: 'Доставка',
        image: '//0.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/6_6a01ce0ba511e3935327bc487ec44f4f.svg',
        mobileImage: '//2.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/6_9883823b8cb080becde9209b7f0be0e4.png',
      },
      {
        text: 'Монтаж',
        image: '//1.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/7_905b747446ebb1c00fdfb855d1d02e05.svg',
        mobileImage: '//1.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/7_59f190c09752e86c615b8390d79564fd.png',
      },
      {
        text: 'Подключение',
        image: '//2.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/8_9fb06b70b83debee7fe516c72182987e.svg',
        mobileImage: '//1.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/8_07f29337f78d2edbaa050e5619283d92.png',
      },
      {
        text: 'Техническая поддержка',
        image: '//1.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/9_4e0b3155ee71ddab36f1bf38878127ef.svg',
        mobileImage: '//1.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/9_21c9c1381c534cd60ebb040790fa5354.png',
      },
    ],
  },
  {
    isOverflow: true,
    bg: {
      color: '#fff',
    },
    form: true,
    header: {
      isLeft: false,
      isWhite: false,
      mobileGradient: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
    },
    content: [
      {
        title: 'Оставьте заявку',
      },
    ],
  },
];
