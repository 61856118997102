import React, { useState, useEffect } from 'react';
import MobileItem from '../../components/MobileItem';
import { data } from '../data';

import { S } from './styles';

const PikElementContainerMobile = () => {
  const [screenHeight, handleScreenHeight] = useState(0);

  useEffect(() => {
    const { body: { clientHeight } } = document;
    handleScreenHeight(clientHeight);
  }, []);

  return (
    <S.PikElementContainerMobile>
      { data.map((item) => (
        <MobileItem item={item} screenHeight={screenHeight} />
      )) }
    </S.PikElementContainerMobile>
  );
};

export default PikElementContainerMobile;
