import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { Typography } from '@pik/pik-ui';
import * as lottieData from '../../containers/lottie.json';
import Video from '../Video';
import Accordion from '../Accordion';
import Form from '../Form';

import { S } from './styles';

const calculateWrapperTop = (scroll, height, length, topLimitScroll, bottomLimit) => {
  const checkHeight = height * length - height;
  let result = scroll;

  if (result > checkHeight) {
    result = checkHeight;
  }

  if (length === 1) {
    result = height;

    if (scroll > topLimitScroll && (scroll + height) < bottomLimit) {
      result = height - (scroll - topLimitScroll);
    }

    if ((scroll + height) >= bottomLimit) {
      result = 0;
    }

    return result;
  }

  return result * -1;
};

const calculateTextOpacity = (scroll, topLimit, height, i) => {
  const defaultValue = 0.5;
  const start = (topLimit + height * i) - (height / 2);
  const finish = start + height;
  const center = start < 0 ? finish + start : finish - start;
  let result = defaultValue;

  if (scroll > start && scroll < finish) {
    if (scroll < center) {
      result = (scroll * defaultValue) / start;
    }

    if (scroll === center) {
      result = 1;
    }

    if (scroll > center) {
      result = 1 - ((scroll * defaultValue) / finish);
    }
  }

  if (scroll > center && center > 0) {
    result = 1;
  }

  return result > defaultValue ? result : defaultValue;
};

const calculateHeadTextOpacity = (scroll, topLimit, height) => {
  const start = topLimit;
  const finish = topLimit + height;
  let result = 1;

  if (scroll <= start) {
    result = 1;
  }

  if (scroll > start && scroll < finish) {
    result = 1 - (scroll - topLimit) / height;
  }

  if (scroll >= finish) {
    result = 0;
  }

  return result;
};

const getHeadText = (title, desc) => {
  let result = `<span>${ title }</span>`;

  if (desc) {
    result = `<span>${ title }</span><br/>${ desc }`;
  }

  return result;
};

const handleSvgHover = (e, i, svgRef, handleSvgTooltip) => {
  const { current } = svgRef;
  const { target } = e;
  const { top: svgTop, left: svgLeft } = current.getBoundingClientRect();
  const { top, left } = target.getBoundingClientRect();

  handleSvgTooltip({
    id: `svg_text_${ i }`,
    top: top - svgTop,
    left: left - svgLeft,
  });
};

const renderSvg = (
  svg, svgTooltip, handleSvgTooltip, svgRef, showTooltips, handleShowTooltips,
) => {
  if (svg) {
    return (
      <>
        <S.Svg ref={svgRef} onMouseEnter={() => handleShowTooltips(true)}>
          { svg.map(({ code, text }, i) => (
            <S.SvgGroup
              onMouseEnter={(e) => handleSvgHover(e, i, svgRef, handleSvgTooltip)}
              id={`svg_group_${ i }`}
              key={`svg_group_${ text }`}
              showTooltips={showTooltips}
            >
              { code.map((d) => (
                <S.Path key={d} d={d} />
              )) }
            </S.SvgGroup>
          )) }
        </S.Svg>
        { svg.map(({ text }, i) => (
          <S.PathText
            svgTooltip={svgTooltip}
            id={`svg_text_${ i }`}
            key={`svg_text_${ text }`}
            onMouseLeave={() => handleSvgTooltip(null)}
          >
            <Typography weight="medium" dangerouslySetInnerHTML={text} />
          </S.PathText>
        )) }
      </>
    );
  }

  return null;
};

const renderBackground = (
  image, lottie, svg, svgTooltip, handleSvgTooltip, svgRef, showTooltips, handleShowTooltips,
) => {
  if (lottie) {
    const { default: animationData } = lottieData;

    return (
      <S.Lottie>
        <Lottie options={{ animationData, loop: true, autoplay: true }} />
      </S.Lottie>
    );
  }

  if (image) {
    return (
      <S.Image bg={image}>
        { renderSvg(
          svg, svgTooltip, handleSvgTooltip, svgRef, showTooltips, handleShowTooltips,
        ) }
      </S.Image>
    );
  }

  return null;
};

const renderText = (text, scroll, topLimit, height, i) => {
  if (text) {
    const textOpacity = calculateTextOpacity(scroll, topLimit, height, i);

    return (
      <S.TextCenter opacity={textOpacity}>
        <Typography weight="medium" dangerouslySetInnerHTML={text} />
      </S.TextCenter>
    );
  }

  return null;
};

const renderHeadText = (title, desc, isVideo, isAccordion, scroll, topLimit, height) => {
  if (title) {
    const text = getHeadText(title, desc);
    const opacity = calculateHeadTextOpacity(scroll, topLimit, height);

    return (
      <S.TextHead opacity={opacity} isVideo={isVideo} isAccordion={isAccordion}>
        <Typography weight="semiBold" dangerouslySetInnerHTML={text} />
      </S.TextHead>
    );
  }

  return null;
};

const renderVideo = (video, height) => {
  if (video) {
    return (
      <S.Video height={height}>
        <Video video={video} />
      </S.Video>
    );
  }
  return null;
};

const renderTableRight = (tableRight) => {
  if (tableRight) {
    return (
      <S.TableRight>
        { tableRight.map((item) => {
          const { name, text } = item;

          return (
            <S.TableRightItem key={text}>
              <S.TableRightItemName>
                <Typography weight="semiBold" dangerouslySetInnerHTML={name} />
              </S.TableRightItemName>
              <S.TableRightItemText>
                <Typography dangerouslySetInnerHTML={text} />
              </S.TableRightItemText>
            </S.TableRightItem>
          );
        }) }
      </S.TableRight>
    );
  }

  return null;
};

const renderTableLeft = (tableLeft) => {
  if (tableLeft) {
    return (
      <S.TableLeft>
        { tableLeft.map((text) => (
          <S.TableLeftItem key={text}>
            <Typography weight="medium" dangerouslySetInnerHTML={text} />
          </S.TableLeftItem>
        )) }
      </S.TableLeft>
    );
  }

  return null;
};

const renderAccordion = (accordion, accordionHeight, scroll, topLimit, color) => {
  if (accordion) {
    return (
      <S.Accordion>
        <Accordion
          accordion={accordion}
          accordionHeight={accordionHeight}
          scroll={scroll}
          topLimit={topLimit}
          color={color}
        />
      </S.Accordion>
    );
  }

  return null;
};

const renderForm = (form) => {
  if (form) {
    return (<Form />);
  }

  return null;
};

const renderContentItem = (
  item, i, height, accordion, accordionHeight, scroll, topLimit, color, form,
) => {
  const {
    text, title, desc, tableRight, tableLeft, video,
  } = item;
  const isCenter = !!text;
  const isVideo = !!video;
  const isAccordion = !!accordion;

  return (
    <S.Item
      key={`${ height }_${ i }`}
      height={height}
      isCenter={isCenter}
    >
      { renderVideo(video, height) }
      { renderText(text, scroll, topLimit, height, i) }
      { renderHeadText(title, desc, isVideo, isAccordion, scroll, topLimit, height) }
      { renderTableRight(tableRight) }
      { renderTableLeft(tableLeft) }
      { renderAccordion(accordion, accordionHeight, scroll, topLimit, color) }
      { renderForm(form) }
    </S.Item>
  );
};

const Content = ({
  content,
  bg,
  height,
  scroll,
  topLimitScroll,
  bottomLimit,
  accordion,
  accordionHeight,
  topLimit,
  form,
}) => {
  const {
    image, lottie, color, svg,
  } = bg;
  const wrapperTop = calculateWrapperTop(
    scroll,
    height,
    content.length,
    topLimitScroll,
    bottomLimit,
  );
  const svgRef = useRef(null);
  const [showTooltips, handleShowTooltips] = useState(false);
  const [svgTooltip, handleSvgTooltip] = useState(null);

  return (
    <S.Content>
      <S.BackGround height={height}>
        { renderBackground(
          image,
          lottie,
          svg,
          svgTooltip,
          handleSvgTooltip,
          svgRef,
          showTooltips,
          handleShowTooltips,
        ) }
      </S.BackGround>
      <S.Wrapper height={height} top={wrapperTop}>
        { content.map((item, i) => renderContentItem(
          item,
          i,
          height,
          accordion,
          accordionHeight,
          scroll,
          topLimit,
          color,
          form,
        )) }
      </S.Wrapper>
    </S.Content>
  );
};

Content.propTypes = {
  content: PropTypes.shape([]).isRequired,
  bg: PropTypes.shape({
    image: PropTypes.string,
    lottie: PropTypes.bool,
    color: PropTypes.string,
    svg: PropTypes.shape({}),
  }).isRequired,
  height: PropTypes.number.isRequired,
  scroll: PropTypes.number.isRequired,
  topLimitScroll: PropTypes.number.isRequired,
  bottomLimit: PropTypes.number.isRequired,
  topLimit: PropTypes.number.isRequired,
  accordion: PropTypes.shape({}),
  accordionHeight: PropTypes.number.isRequired,
  form: PropTypes.bool,
};

Content.defaultProps = {
  accordion: null,
  form: false,
};

export default Content;
