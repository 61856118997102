import styled from 'styled-components';
import { mixins } from '@pik/pik-ui';

export const S = {};

S.MobileItem = styled.div`
  box-sizing: border-box;
  position: relative;
  height: auto;
  min-height: ${ ({ minHeight }) => `${ minHeight }px` };
  background: ${ ({ bg }) => bg };
  padding: 60px 0;
  
  &:last-child{
    padding: 60px 0 0;  
  }
`;

S.Content = styled.div`
  box-sizing: border-box;
`;

S.Video = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${ ({ height }) => `${ height }px` };
  position: absolute;
  top: 0;
  left: 0;
`;

S.TextHead = styled.div`
  box-sizing: border-box;
  width: calc(100% - 40px);
  margin: 0 auto;
  
  .Typography {
    font-size: 20px;
    line-height: 23px;
    color: ${ ({ isVideo, isAccordion }) => ((isVideo || isAccordion) ? '#fff' : '#939393') };
    
    span {
      color: ${ ({ isAccordion }) => (isAccordion ? '#000' : '#FF4114') };
    }
  }
`;

S.Lottie = styled.div`
  box-sizing: border-box;
  width: calc(100% - 40px);
  margin: 0 auto 94px;
`;

S.Image = styled.div`
  box-sizing: border-box;
  width: calc(100% - 40px);
  margin: 0 auto;
  position: relative;
`;

S.Img = styled.img`
  box-sizing: border-box;
  width: 100%;
`;

S.Svg = styled.svg.attrs(() => ({
  viewBox: '0 0 600 800',
}))`
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

S.SvgGroup = styled.g`
  cursor: pointer;
  position: relative;
`;

S.Path = styled.path`
  fill: #fff;
  
  &:first-child {
    fill: #FF4114;
  }
`;

S.PathText = styled.div`
  box-sizing: border-box;
  position: absolute;
  white-space: nowrap;
  background: #000;
  padding: 10px;
  min-height: 32px;
  border-radius: 8px;
  z-index: 2;
  cursor: pointer;
  ${ mixins.flex() };
  
  ${ ({ svgTooltip, id }) => (svgTooltip && id === svgTooltip.id ? `
    opacity: 1;
    visibility: visible;
    top: ${ svgTooltip.top }px;
    left: ${ svgTooltip.left }px;
    transform: ${ svgTooltip.transform };
  ` : `
    opacity: 0;
    visibility: hidden;
  `) }
  
  .Typography {
    color: #fff;
    font-size: 12px;
    line-height: 14px;
  }
`;

S.Table = styled.div`
  box-sizing: border-box;
  width: calc(100% - 40px);
  margin: 0 auto;
`;

S.Item = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid #EAEAEA;
  padding: 18px 0;
  
  &:last-child {
    border-bottom: 0;
  }
`;

S.Name = styled.div`
  box-sizing: border-box;
  margin-bottom: 4px;
  
  .Typography {
    font-size: 20px;
    line-height: 20px;
    color: #000000;
  }
`;

S.Text = styled.div`
  box-sizing: border-box;
  
  .Typography {
    font-size: 13px;
    line-height: 13px;
    color: #808080;
  }
`;

S.TextBlock = styled.div`
  box-sizing: border-box;
  width: calc(100% - 40px);
  margin: 0 auto;
  
  .Typography {
    font-size: 17px;
    line-height: 24px;
    color: #fff;
    
    p {
      margin-bottom: 24px;
      font-weight: 600;
      
      &:last-child {
        margin-bottom: 0;
        font-weight: 400;
      }
    }
  }
`;

S.Accordion = styled.div`
  box-sizing: border-box;
  width: calc(100% - 40px);
  margin: 20px auto 0;
  ${ mixins.flex('row', 'wrap', 'space-between', 'flex-start') };
`;

S.AccordionItem = styled.div`
  box-sizing: border-box;
  ${ mixins.flexWidth('calc(50% - 20px)') };
  border-bottom: 1px solid #000;
  padding-top: 20px;
  height: 180px;
`;

S.AccordionImage = styled.div`
  box-sizing: border-box;
  width: 63px;
  height: 80px;
  background: url(${ ({ bg }) => bg }) no-repeat center;
  background-size: contain;
`;

S.AccordionText = styled.div`
  box-sizing: border-box;
  
  .Typography {
    font-size: 14px;
    line-height: 20px;
  }
`;
