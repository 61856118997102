import styled from 'styled-components';

export const S = {};

S.Items = styled.div`
  box-sizing: border-box;
  height: 100%;
  position: relative;
`;

S.Block = styled.div.attrs(({ top, height }) => ({
  style: { transform: `translateY(${ top }px)`, height: `${ height }px` },
}))`
  box-sizing: border-box;
  background: ${ ({ bg }) => bg };
  position: fixed;
  overflow: hidden;
  left: 0;
  width: 100%;
`;

S.Content = styled.div.attrs(({ isOverflow, top }) => (isOverflow ? ({
  style: { transform: `translateY(${ top }px)` },
}) : null))`
  box-sizing: border-box;
  height: ${ ({ height }) => `${ height }px` };
  width: 100%;
  ${ ({ isOverflow }) => (isOverflow ? `
    position: absolute;
    left: 0;
  ` : null) };
`;
