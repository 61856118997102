import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Head from 'next/head';
import PikElementContainer from '../containers/PikElementContainer';
import PikElementContainerMobile from '../containers/PikElementContainerMobile';

const renderContainer = (screenType) => {
  const isMobile = screenType === 'mobile';

  if (isMobile) {
    return (
      <PikElementContainerMobile />
    );
  }

  return (
    <PikElementContainer />
  );
};

const PikElement = ({ screenType }) => (
  <>
    <Head>
      <title>ПИК-Модуль</title>
    </Head>
    { renderContainer(screenType) }
  </>
);

PikElement.propTypes = {
  screenType: PropTypes.string,
};

PikElement.defaultProps = {
  screenType: 'desktop',
};

const mapStateToProps = ({ appService }) => ({
  screenType: appService.screenType,
});

export default connect(mapStateToProps)(PikElement);

// import React from 'react';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
// import Head from 'next/head';
// import Container from '../containers/Container';
//
// const PikElement = ({ screenType }) => (
//   <>
//     <Head>
//       <title>ПИК-Элемент</title>
//     </Head>
//     <Container />
//   </>
// );
//
// PikElement.propTypes = {
//   screenType: PropTypes.string,
// };
//
// PikElement.defaultProps = {
//   screenType: 'desktop',
// };
//
// const mapStateToProps = ({ appService }) => ({
//   screenType: appService.screenType,
// });
//
// export default connect(mapStateToProps)(PikElement);
