const validate = {
  tel: {
    test: /^\+7\s\(\(?\d{3}\)\s\d{3}\-\d{2}\-\d{2}$/,
    mask: '+7 (999) 999-99-99',
    type: 'tel',
    message: 'Неправильный формат телефона',
  },
  newTel: {
    test: /^\+7\s\d{3}\s\d{3}\-\d{2}\-\d{2}$/,
    mask: '+7 999 999-99-99',
    type: 'tel',
    message: 'Неправильный формат телефона',
  },
  email: {
    test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    mask: null,
    type: 'text',
    message: 'Неправильный формат email',
  },
  text: {
    test: '',
    mask: null,
    type: 'text',
    message: '',
  },
  code: {
    test: '',
    mask: null,
    type: 'tel',
    message: '',
  },
  password: {
    test: '',
    mask: null,
    type: 'password',
    message: '',
  },
  number: {
    test: '',
    mask: null,
    type: 'number',
    message: '',
  },
  area: {
    test: '',
    mask: null,
    type: 'number',
    message: '',
  },
  passport: {
    test: /(\d{4})+\s+(\d{6})/,
    mask: '9999 999999',
    type: 'text',
    message: 'Некорректный номер паспорта',
  },
  date: {
    test: /^[0-9]{2}[\.][0-9]{2}[\.][0-9]{4}$/,
    mask: '99.99.9999',
    type: 'text',
    message: 'Некорректная дата',
  },
  fio: {
    test: /[\wа-яёА-ЯЁ]+\s+[\wа-яёА-ЯЁ]+\s+[\wа-яёА-ЯЁ]+/,
    mask: '',
    type: 'text',
    message: 'Некорректно введено ФИО',
  },
  birthday: {
    test: /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/,
    mask: '99.99.9999',
    type: 'text',
    message: 'Некорректная дата',
  },
};

export default {
  validate,
};
