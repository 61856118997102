import styled from 'styled-components';
import { mixins, media } from '@pik/pik-ui';

export const S = {};

S.Header = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 20px;
  ${({ isLeft }) => mixins.flex('row', 'nowrap', `${isLeft ? 'flex-start' : 'center'}`, 'center')};
  ${({ mobileGradient }) => (mobileGradient ? `
    background: ${mobileGradient};
  ` : null)};
  
  .Typography {
    color: ${({ isWhite }) => (isWhite ? '#fff' : '#000')};
    font-size: 26px;
    line-height: 26px;
  }
  
  @media ${media.max768} {
    padding: 20px 20px 0;
    height: 80px;
    ${mixins.flex('row', 'nowrap', 'space-between', 'flex-start')};
    
    .Typography {
      font-size: 20px;
      line-height: 20px;
    }
  }
`;


S.Logo = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  width: 52px;
  
  svg {
    width: 100%;
    height: 100%;
    
    path {
      fill: ${({ isWhite }) => (isWhite ? '#fff' : '#000')};
    }
  }
  
  @media ${media.max768} {
    top: 0;
    transform: translateY(0);
    
    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
