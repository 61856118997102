import styled from 'styled-components';
import { mixins, media } from '@pik/pik-ui';

const { max1024, max768 } = media;

export const S = {};

S.Wrapper = styled.div`
  height: 100%;
  position: relative;
  ${mixins.flex('column', '', 'space-between', 'flex-end')};
  
  @media ${max768} {
    min-height: 88vh;
  }
`;

S.FormContainer = styled.div`
  height: auto;
  width: 55%;
  
  @media ${max768} {
    width: 100%;
  }
`;

S.Form = styled.div`
  ${mixins.flex('row', 'wrap', 'space-between', 'center')};
  max-width: 100%;
  padding: 100px 20px 100px 0;
  box-sizing: border-box;
  
  
  @media ${max768} {
    padding: 15px 0 0;
  }
`;

S.Input = styled.div`
 flex-basis: ${({ width }) => (width || '100%')};
 margin-bottom: 40px;  
 
  @media ${max768} {
    flex-basis: 100%;
    margin-bottom: 10px;  
  } 
`;

S.Button = styled.div`
  width: 100%;
  
  button { 
    height: 80px;
    border-width: 4px;
    border-color: ${({ success, disabled }) => (success ? 'transparent' : !disabled ? '#000' : '#EAEAEA')};
    
    &:hover {
      border-width: 4px;
      border-color: ${({ success, disabled }) => (success ? 'transparent' : !disabled ? '#000' : '#EAEAEA')};
      
      span {
        color: ${({ success, disabled }) => (success || !disabled ? '#000' : '#EAEAEA')};
      }
    }
    
    span {
      font-size: 28px;
      line-height: 56px;
      color: ${({ success, disabled }) => (success || !disabled ? '#000' : '#EAEAEA')};
    }
  }
  
  .Icons {
    fill: #000 !important;
    width: 40px;
    height: 40px;
  }
  
  @media ${max768} {
    margin-top: 24px;
    padding: 0 20px;
    box-sizing: border-box;
    
    button {
      height: 60px;
      border-width: 3px;
      
      &:hover {
        border-width: 3px;
      }
      
      span {
        font-size: 20px;
        line-height: 51px;
      }
    }  
  }
`;

S.Disclaimer = styled.div`
  ${mixins.flex('', '', 'center', 'center')};
  height: auto;
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  
  @media ${max768} {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
  } 
`;

S.Email = styled.a`
  color: rgb(255,65,20);
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  position: relative;
  
 @media ${max768} {
    font-size: 12px;
    line-height: 20px;
  } 
`;

S.EmailBorder = styled.span`
 background-color: rgba(255,65,20,0.21);
 width: 100%;
 height: 1px;
 position: absolute;
 bottom: 10px;
 left: 0;
 
 @media ${max768} {
  bottom: 3px;
 } 

`;

S.Phone = styled.a`
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  
  @media ${max768} {
    font-size: 12px;
    line-height: 20px; 
    margin-left: 0;
  } 
`;

S.PhoneText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  
  @media ${max768} {
    font-size: 12px;
    line-height: 20px; 
    margin-left: 0;
  } 
`;


S.DisclaimerBlock = styled.div`
  ${mixins.flex('column', '', 'flex-start', 'flex-start')};
  margin-right: 54px;
  
  &:last-child {
    margin-right: 0;
  }
  
  @media ${max768} {
    margin-top: 20px;
      margin-right: 20px;
  } 
`;
