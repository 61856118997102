import styled from 'styled-components';
import { mixins } from '@pik/pik-ui';

export const S = {};

S.Accordion = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${ ({ height }) => `${ height }px` };
  position: relative;
  overflow: hidden;
`;

S.Item = styled.div.attrs(({ top }) => ({
  style: { top: `${ top }px` },
}))`
  box-sizing: border-box;
  width: 100%;
  height: ${ ({ height }) => `${ height }px` };
  position: absolute;
  ${ mixins.flex('row', 'nowrap', 'flex-start', 'center') };
  border-top: 1px solid #000;
  padding-left: 182px;
  overflow: hidden;
  background: ${ ({ bg }) => bg };
  
  .Typography {
    font-size: 32px;
    line-height: 32px;
    color: #000;
  }
`;

S.Bg = styled.div`
  box-sizing: border-box;
  position: absolute;
  background: url(${ ({ bg }) => bg }) no-repeat center;
  width: 131px;
  height: ${ ({ height }) => `${ height + 10 }px` };
  background-size: contain;
  top: 0;
  left: 12px;
`;
