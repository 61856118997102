import styled from 'styled-components';
import { media, styleHelpers, mixins } from '@pik/pik-ui';

const { colors, fontSize, lineHeight } = styleHelpers;

const state = {
  placeholder: {
    default: `
        opacity: 1   
        `,
    focus: `
        opacity: 0      
        `,
    basic: `
        color: ;
    `,
    accent: `
        color: ;
    `,
  },
  size: {
    m: `
    48px;
      `,
    l: `
    80px;
      `,
  },

  paddingInput: {
    m: 'padding: 26px 40px 10px 12px;',
    l: 'padding: 10px 40px 10px 12px;',
  },

  paddingInputWithIcon: {
    m: 'padding: 26px 40px 10px 40px;',
    l: 'padding: 10px 40px 10px 40px;',
  },

  paddingInputWithIconWithoutPlaceholder: {
    m: 'padding: 10px 40px;',
    l: 'padding: 10px 40px;',
  },

  border: {
    start: '4px 0 0 4px',
    end: '0 4px 4px 0',
    middle: '0',
  },
};

// eslint-disable-next-line no-nested-ternary
const getColor = (error, success, focus, hover, inputStyle) => (error ? 'Error' : success ? 'Success' : focus ? 'Focus' : hover ? 'Hover' : inputStyle === 'accent' ? 'Transparent' : 'Normal');

// eslint-disable-next-line no-nested-ternary
const getIconColor = (error, success, focus, hover, inputStyle) => (error ? 'stateError' : success ? 'stateSuccess' : focus ? 'stateFocus' : hover ? 'stateHover' : inputStyle === 'accent' ? 'grayLight' : 'stateNormal');

export const Input = styled.div`
  @keyframes onAutoFillStart {
  }
  @keyframes onAutoFillCancel {
  }
  
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: ${({ focus }) => (focus ? 3 : 'auto')};
    border-style: solid;
    border-width: ${({ focus, error, success }) => (focus || error || success ? '2px' : '1px')};
    border-color: ${({
    error, success, focus, inputStyle,
  }) => styleHelpers.colors[`state${getColor(error, false, false, false, inputStyle)}`]};
    ${({ groupPosition }) => groupPosition && `border-radius: ${state.border[groupPosition]};`};
    pointer-events: none; 
    transition: border-color .15s cubic-bezier(0.19, 1, 0.22, 1),border-width .15s cubic-bezier(0.19, 1, 0.22, 1),border .15s cubic-bezier(0.19, 1, 0.22, 1);
    user-select: none;
  }

  input {
      width: 100%;
      display: block;
      box-sizing: border-box;     
      color: ${({ disabled }) => (disabled ? mixins.hex2rgb('#000', '0.5') : '#000')};
      letter-spacing: 0;
      font-size: 28px;
      line-height: 56px;
      height: ${({ size }) => state.size[size]};
      border: none;
      box-shadow: none;
      // ${({ groupPosition }) => groupPosition && `border-radius: ${state.border[groupPosition]};`};
      background-color: #EFEFEF !important;
      ${({ size, icon, placeholder }) => (icon ? placeholder ? state.paddingInputWithIcon[size] : state.paddingInputWithIconWithoutPlaceholder[size] : state.paddingInput[size])};
      ${mixins.animation('all', '.3s', 'ease')};
      
      @media ${media.max768} {
        height: 60px;
        font-size: 20px;
        line-height: 56px;
      } 
      
      -webkit-appearance: none;
      -moz-appearance:textfield;
      
      &:hover {
        ${mixins.animation('all', '.3s', 'ease')};
      }
      
      ::-webkit-inner-spin-button{
        display: none !important;
        
      }
      
      :-webkit-autofill,
      :-webkit-autofill:hover, 
      :-webkit-autofill:selected, 
      :-webkit-autofill:focus {
        -webkit-background-color: #EFEFEF !important;
        -webkit-text-fill-color: #000000 !important;
        -webkit-box-shadow: inset 0 0 0 50px ${({ focus, inputStyle }) => (inputStyle === 'accent' && !focus ? colors.secondaryExtraLight : colors.white)};
      }
      
      :-webkit-autofill {
        animation-duration: 50000s;
        animation-name: onAutoFillStart;
      }
      
      :not(:-webkit-autofill) {
        animation-duration: 50000s;
        animation-name: onAutoFillCancel;
      }
  }
  
  // &:hover {
  //   &::after {
  //     z-index: 2;
  //     border-color: ${({ error, success, focus }) => styleHelpers.colors[`state${getColor(error, success, focus, true)}`]};
  //   }
  // }
  
  @media ${media.max1024} {
    input,
    &::after {
      
    }
  }
`;

export const TextFieldWrapper = styled.div`
  width: 100%;
  position: relative;  
  height: ${({ size }) => state.size[size]};
  ${({ disabled }) => disabled && `
    pointer-events: none;
    user-select: none;
  `};
  
  @media ${media.max768} {
    height: 60px;
  }
`;

export const Placeholder = styled.span`
  z-index: 1;
  pointer-events: none;
  font-size: 28px;
  line-height: 56px;
  color: ${mixins.hex2rgb('#000000', '0.2')};
  letter-spacing: 0;
  position: absolute;
  left: ${({ icon }) => (icon ? '40px' : '12px')};
  ${mixins.flex('row', '', 'flex-start', 'center')};
  transition: color .15s cubic-bezier(0.19, 1, 0.22, 1),transform .15s cubic-bezier(0.19, 1, 0.22, 1);
  height: ${({ size }) => state.size[size]};
  ${({ value, focus, autofill }) => state.placeholder[value || autofill ? 'focus' : 'default']};
  
  @media ${media.max768} {
    height: 60px;
    font-size: 20px;
    line-height: 56px;
  }
  
  ${({ error, success }) => {
    !error && !success
      ? `${TextFieldWrapper}:hover & {
        color: ${({ inputStyle }) => (inputStyle === 'basic'
    ? mixins.hex2rgb(colors.black, 0.6)
    : mixins.hex2rgb(colors.secondaryDark, 0.6))};
    }`
      : '';
  }};
  
`;

export const ErrorMessage = styled.div`
    position: absolute;
    font-size: 11px;
    right: 0;
    color: ${styleHelpers.colors.stateError};
    padding-top: 3px;
`;

export const SuccessIcon = styled.div`
    position: absolute;
    fill: ${styleHelpers.colors.stateSuccess};
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
`;

export const Suggestions = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 44px;
    color: #484848;
    letter-spacing: 0;
    transition: background-color 0.1s ease-in-out;
    cursor: pointer;
    position: relative;
    padding-left: 10px;
    font-size: 14px;
`;

export const ShowPasswordIcon = styled.div`
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);

  .Icons {
    fill: ${({ active }) => (active ? styleHelpers.colors.grayDark : styleHelpers.colors.grayExtraLight)};
    transition: fill .3s ease;
  }
`;

export const Icon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
  
  .Icons {
    svg {
      fill: ${({
    error, success, focus, inputStyle,
  }) => (focus ? styleHelpers.colors.black : styleHelpers.colors[getIconColor(error, success, false, false, inputStyle)])};
      ${mixins.animation('all', '.3s', 'ease')};
    }
  }
`;

export const Clear = styled.div`
  ${mixins.flex()};
  position: absolute;
  height: ${({ size }) => state.size[size]};
  top: 0;
  right: 12px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  ${({ show }) => (show ? `
    opacity: 1;
    visibility: visible;
  ` : '')};
  ${mixins.animation('opacity, visibility')};
  .Icons {
    width: 10px;
    height: 10px;
    transition: fill .4s ease;
    cursor: pointer;
    svg {
      ${mixins.animation('fill')};
      fill: ${styleHelpers.colors.grayLight};
    }
    &:hover {
      svg {
        fill: ${styleHelpers.colors.gray};
      }
    }
  }
  
  @media ${media.max768} {
      height: 60px;
  }
`;
